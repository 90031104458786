#header {
  z-index: 1;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}
#nav,
#login-nav {
  margin: 0 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#login-nav {
  justify-content: center;
}
.nav-logo {
  cursor: pointer;
  margin-left: 10px;
}
.nav-links {
  display: none;
}
.nav-link {
  text-transform: uppercase;
}
.nav-mobile {
  display: block;
}
.nav-mobile-btn {
  padding-right: 10px;
  width: 40px;
  cursor: pointer;
}
.nav-links-mobile {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}
.nav-links-mobile a {
  padding: 10px;
}
.nav-buttons {
  display: none;
}
.sign-right {
  display: inline-flex;
  margin: 20px 10px;
}
.sign-right p {
  margin: 0 10px 0 0;
}
.signout-link {
  margin: 20px 5px;
  font-size: 12px;
}
.nav-mobile-menu {
  display: none;
  text-align: center;
  position: absolute;
  top: 110%;
  left: 1.5%;
  background-color: var(--bg-light-purple);
  width: 97%;
  box-shadow: var(--shadow1);
  z-index: 1;
  border-radius: 20px;
}
.nav-mobile-menu-content {
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.on-nav {
  display: block;
}

@media only screen and (min-width: 650px) {
  .nav-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nav-mobile {
    display: none;
  }
  .nav-mobile-btn {
    display: none;
  }
  .nav-right {
    margin-right: 10px;
  }
  .signout-link {
    margin: 20px 10px;
    font-size: 14px;
  }
}
@media only screen and (min-width: 990px) {
  #nav,
  #login-nav {
    justify-content: space-around;
  }
  .nav-links {
    display: block;
  }
  .nav-link {
    font-size: 14px;
    margin: 0 10px;
  }
  .nav-link:hover {
    color: var(--dark-green);
    text-decoration: underline;
  }
}
