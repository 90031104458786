.objetivos-container {
    height: 1850px;
    margin: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.objetivos-container h3 {
    max-width: 600px;
    margin: auto;
}
.objetivos-title {
    max-width: 500px;
    margin-bottom: 50px;
}
.objetivos-box {
    margin: 10px;

    background: var(--gradient);
    padding: 3px;
    border-radius: 10px;
    box-shadow: var(--shadow1);
}
.objetivos-box-content {
    width: 330px;
    height: 480px;
    border-radius: 8px;
    
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.objetivos-box-content h4 {
    margin-top: 0px;
}
.objetivos-box-content h6 {
    margin: 0px;
}
.objetivos-img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;

    background: var(--gradient-vertical);
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }
.icon-objetivos {
height: 30px;
color: white;
pointer-events: none;
}
.objetivos-text {
    width: 320px;
    height: 140px;
    margin-bottom: 10px;
}
.objetivos-text h4 {
    margin-bottom: 10px;
}
.objetivos-box-content p {
    font-weight: 900;
}
.objetivos-list {
    width: 320px;
    height: 170px;
    margin-bottom: 10px;
}
.objetivos-list p {
    margin: 16px 0 5px 0;
}
.objetivos-box-content ul {
    margin: 0;
}
.objetivos-box-content li {
    text-align: left;
    padding: 4px 0;
}
@media only screen and (min-width: 620px){
    .objetivos-container {
        margin-top: 30px;
    }
}
@media only screen and (min-width: 750px){
    .objetivos-container {
        height: 1570px;
        justify-content: center;
    }
    .objetivos-title {
        max-width: 910px;
    }
    .objetivos-container h2 {
        margin-bottom: 20px;
    }
    .objetivos-container-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}
@media only screen and (min-width: 1118px){
    .objetivos-container {
        height: 1050px;
    }
  }