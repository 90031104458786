:root {
  --green: #02C9B7;
  --purple: #7060C1;
  --bg-light-green: #E6FAF8;
  --bg-light-purple: #F2EFFF;
  --bg-light-gray: #F4F4F4;
  --pink: #9A348E;
  --light-pink: #E5B3DF;
  --light-green: #58FDEE;
  --light-purple: #9286D0;
  --dark-pink: #722769;
  --dark-green: #02978A;
  --dark-purple: #5543AC;
  --yellow: #FEE52C;
  --white: #FFFFFF;
  --light-gray: #F4F4F4;
  --gray: #666969;
  --dark-gray: #2C403D;
  --black: #1A2523;
  --gradient: linear-gradient(90deg, #02C9B7 0%, #7060C1 100%);
  --gradient-dark: linear-gradient(90deg, #01655C 0%, #7060C1 100%);
  --grandient-light: linear-gradient(90deg, rgba(1, 101, 92, 0.8) 0%, #7060C1 100%);
  --gradient-vertical: linear-gradient(180deg, #02C9B7 0%, #7060C1 100%);
  --gradient-vdark: linear-gradient(180deg, #01655C 0%, #7060C1 100%);
  --grandient-vlight: linear-gradient(180deg, rgba(1, 101, 92, 0.8) 0%, #7060C1 100%);
  --shadow1: 0px 2px 8px rgba(0, 0, 0, 0.25);
  --shadow2: 0px 4px 10px rgba(0, 0, 0, 0.15);
  --shadow-solid: 0px 2px 0px rgba(0, 0, 0, 0.1);
  --error: #d65454;
}
::selection {
  background: var(#02c9b859);
}
html, 
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: var(--dark-gray);
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}
h1,
h2,
h3,
h4,
.process-number,
.process-text {
  font-family: 'Merriweather', serif;
  color: var(--dark-gray);
}
h1,
h2,
h4,
.process-number,
.process-text {
  font-weight: 900;
}
h5, 
h6,
.contact-text {
  font-family: 'Open Sans', sans-serif;
  color: var(--dark-gray);
}
h1,
.process-number {
  font-size: 3.5rem;
}
h2 {
  font-size: 2.25rem;
}
h3 {
  font-size: 1.6rem;
  font-weight: 400;
}
h4,
.process-text {
  font-size: 1.5rem;
}
h5,
.contact-text {
  font-size: 1.25rem;
  font-weight: 700;
}
h6 {
  font-size: 1.125rem;
  font-weight: 400;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: var(--dark-gray);
}
a:hover {
  color: var(--dark-green);
}
@media only screen and (min-width: 750px){
  body {
    font-size: 16px;
  }
  h1,
  .process-number {
    font-size: 4.7rem;
  }
  h2 {
    font-size: 4.2rem;
  }
  h3 {
    font-size: 1.8rem;
  }
}
/* Button */
button,
.btn-shape {
    /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 30px;
  gap: 5px;
  margin: 0 5px;

  color: white;
  box-shadow: var(--shadow1);
  border-radius: 35px;
  border: none;

  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .3s;
}
.purple-btn{
  background-color: var(--purple);
  border: 2px solid var(--purple);
}
.pink-btn {
  background-color: var(--pink);
  border: 2px solid var(--pink);
}
.outline-purple {
  border: 2px solid var(--purple);
  color: var(--purple);
  background: transparent;
}
.link-purple {
  background: none;
  border: none;
  box-shadow: none;
  border: none;
  color: var(--purple);
  
  padding: 0px;
  margin: 20px 10px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.link-dark {
  text-align: center;
  background: none;
  border: none;
  box-shadow: none;
  border: none;
  border-radius: 10px;
  color: var(--gray);
  
  /* padding: 0px; */
  margin: 0;
  padding: 10px 10px;
  font-size: 14px;
  text-transform: none;
}
/* Button Hover */
.purple-btn:hover {
  border: 2px solid var(--purple);
  color: var(--purple);
  background: transparent;
}
.pink-btn:hover {
  border: 2px solid var(--pink);
  color: var(--pink);
  background: transparent;
}
.outline-purple:hover {
  border: 2px solid var(--purple);
  color: white;
  background: var(--purple);
}
.link-purple:hover {
  color: var(--dark-purple);
  border-bottom: 2px solid var(--dark-purple);
  border-radius: 0px;
}
.link-dark:hover {
  color: var(--dark-gray);
  background-color: var(--bg-light-green);
}
/* Button Active */
.purple-btn:active {
  border: 2px solid var(--dark-purple);
  color: white;
  background: var(--dark-purple);
  transform: scale(0.9);
}
.pink-btn:active {
  border: 2px solid var(--dark-pink);
  color: white;
  background: var(--dark-pink);
  transform: scale(0.9);
}
.outline-purple:active {
  border: 2px solid var(--dark-purple);
  color: white;
  background: var(--dark-purple);
  transform: scale(0.9);
}
.link-purple:active {
  transform: scale(0.9);
}
.link-dark:active {
  color: var(--purple);
  background-color: var(--bg-light-purple);
}
/* Highlight text with gradient */
.highlight {
  animation: gradient-fill 5s linear infinite;
  transform: translateZ(0);
  background-size: 100% 100%;
  background-image: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
}
/* Formas en BG */
.line-top{
  content: " ";
  width: 100%;
  height: 100px;
  position: absolute;
  top: 660px;
  left: 0px;
  background: url(./Assets/Shapes/Rectangle-purple-top-mobile.svg) no-repeat center;
  background-size: contain;
}
.line-bottom {
  content: " ";
  width: 100%;
  height: 70px;
  position: relative;
  top: 0px;
  left: 0px;
  background: url(./Assets/Shapes/Rectangle-purple-white-bottom-mobile.svg) no-repeat center;
  background-size: cover;
}
@media only screen and (min-width: 620px){
  .line-top{
    content: " ";
    height: 240px;
    position: absolute;
    top: 486px;
    left: 0px;
    background: url(./Assets/Shapes/Rectangle-purple-top-desktop.svg) no-repeat center;
    background-size: cover;
  }
  .line-bottom {
    height: 200px;
    top: 50px;
    left: 0px;
    background: url(./Assets/Shapes/Rectangle-purple-white-bottom-desktop.svg) no-repeat center;
    background-size: cover;
  }
}

/* Hero */
#hero {
  display: flex;
  justify-content: center;
}
.hero-content {
  margin: 10px;
  max-width: 450px;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-content h1 {
  margin-top: 0px;
  text-align: center;
}
@media only screen and (min-width: 650px){
  .hero-content {
    max-width: 810px;
  }
}
@media only screen and (min-width: 990px){
  .hero-content {
    max-width: 950px;
  }
}

/* Social icons */
.social-icons-footer{
  display: inline-flex;
  justify-content: center;
}
.social-icons {
  margin: 50px 10px;
}
.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-gray);
}
.social-icon:hover {
  color: var(--dark-green);
}
.svg-inline--fa {
  height: 30px;
  padding: 10px;
}
.social-text {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 900;
}

/* Footer */
#footer {
  background: var(--black);
}
.footer-container {
  padding: 10px;
}
.footer-box {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.nav-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav-footer a {
  padding: 5px;
  color: var(--bg-light-green);
}
.logo-footer {
  margin: 0 auto;
}
.logo-footer:hover {
  filter: invert(18%) sepia(22%) saturate(589%) hue-rotate(121deg) brightness(98%) contrast(85%);
}
.social-icons-footer svg {
  color: var(--bg-light-green);
}
.social-icons-footer svg:hover {
  color: var(--dark-green);
}

/* Made by... */
.footer-copyright {
  color: var(--bg-light-purple);
  padding: 10px;
  border-top: 1px solid var(--dark-gray);
}
.footer-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer-text p {
  font-family:'Courier New', Courier, monospace;
}
.footer-bottom {
  margin: 10px;
}
.svg-inline--fa {
  padding: 0 10px;
}
.footer-link {
  color: var(--light-purple);
}
.footer-heart {
  margin: 0;
  padding: 0;
  height: 15px;
}
@media only screen and (min-width: 650px) {
  .footer-box {
    padding: 80px 0;
    height: auto;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  .nav-footer {
    align-items: flex-start;
  }
  .footer-text {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .logo-footer {
    margin: 0;
  }
}
/* Scroll Top */
.scroll-top {
  position: fixed; 
  left: 80%;
  bottom: 100px;
  height: 20px;
  font-size: 5rem;
  z-index: 1;
  color: var(--purple);
  filter: opacity(0.8);
}
.fa-scroll-top {
  height: 40px;
}
.svg-inline--fa {
  cursor: pointer;
}
@media only screen and (min-width: 650px) {
  .scroll-top {
    left: 90%;
  }
}

/* Forms & Pages*/
#trialClassForm, 
#login {
  margin-top: 100px;
  height: 1010px;
  text-align: center;
}
#form {
  margin: 10px;
}
.infoForm {
  margin: auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infoForm h3 {
  margin: 40px 0 10px 0;
}
.login-link {
  margin: 70px 0 0 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.terms-conditions {
  font-size: 10px;
  max-width: 500px;
  text-align: center;
}
@media only screen and (min-width: 650px) {
  .login-link {
    margin: 70px auto;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Thanks Page */
#thanks,
#error {
  margin-top: 120px;
  /* height: 700px; */
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
#thanks h1, 
#thanks h3 {
  margin: 5px 0 0 0;
}
#thanks h3 {
  width: 50%;
}
.img-see-you {
  width: 100%;
  height: 340px;
  
  background: url(./Assets/images/Seeyou.png) no-repeat center;
  background-size: contain;
}
@media only screen and (min-width: 650px) {
  .img-see-you{
    height: 500px;
  }
}
#error h1 {
  margin-bottom: 0;
}
#error h3 {
  margin: 0;
}
.img-error {
  width: 100%;
  height: 500px;
  background: url(./Assets/images/404.png) no-repeat center;
  background-size: contain;
}

/* Errors pop up */
#error-popup {
  display: none;
  margin: 0 auto;
  max-width: 500px;
  justify-content: center;
  align-items: center;

  color: var(--error);
}